/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { breakpoint, color, font } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

export const RankingWrapper = styled.div`
 @media (${breakpoint.mdMin}) {
    margin-left: -24px;
    margin-right: -24px;
  }
`;

export const WrapperTitle = styled.div`
  h1 {
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 40px;

    @media (${breakpoint.mdMax}) { 
      padding-left: 16px;
    }
  }
`;

export const Header = styled.h1`
  padding: 24px 16px;
  font-weight: 600;
  font-family: ${font.graphikCond};

  @media (${breakpoint.mdMin}) {
    margin-top: 0;
    padding: 32px 24px;
    font-size: 48px;
  }

  @media (${breakpoint.xlMin}) {
    padding: 48px 48px 48px 0;
    position: relative;
    font-size: 60px;
  }
`;

export const DescriptionWrapper = styled.div`
  background-color: ${color.lightBlue};
  display: block;
  min-height: 389px;
  padding-bottom: 70px;

  @media (${breakpoint.xlMin}) {
    padding-bottom: 48px;
    display: flex;

    > div {
      flex: 1 1 50%;
      height: auto;
      margin-bottom: 24px;
    }
  }
`;

export const DescriptionText = styled.div<{ theme: Theme }>`
  height: auto;
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 26.4px;
  font-weight: 300;
  padding: 16px 16px 0 16px;
  position: relative;
  transition: all 0.25s;
  width: 100%;
  font-family: ${font.graphikCond};

  @media (${breakpoint.mdMin}) {
    padding: 24px;
    margin-bottom: 0;
  }

  @media (${breakpoint.xlMin}) {
    font-size: 32px;
    line-height: 35.2px;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 48px;
    padding-top: 48px;
    margin-right: 48px;
    margin-bottom: 24px;
  }

  & > a {
    color: ${color.linkBlue};

    &:hover {
      text-decoration: underline;
    }

    &:active {
      text-decoration: none;
      color: ${getColor('neutralBlack')};
    }
  }
`;

export const CallsToAction = styled.div`
  margin-top: 16px;

  .black-button {
    padding: 16px 32px;
    letter-spacing: unset;
    width: fit-content;
  }

  .link {
    padding: 16px 32px;
    width: fit-content;
  }

  a {
    text-transform: uppercase;
    font-size: 16px;
  }

  @media (${breakpoint.xxlMin}) {
    margin-top: 24px;
  }
`;

export const DescriptionVideo = styled.div`
  overflow: hidden;

  @media (${breakpoint.mdMin}) {
    margin: 0 24px;
  }

  @media (${breakpoint.xlMin}) {
    padding-top: 48px;
    padding-right: 48px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 24px;
  }
`;

export const RankingContentWrapper = styled.div`
  display: block;
  margin: -40px 0 0 0;
  padding: 0 16px;

  @media (${breakpoint.mdMin}) {
    max-width: none;
    padding: 0 24px 0 20px;
  }

  @media (${breakpoint.xlMin}) {
    display: flex;
    padding: 0 48px 0 20px;
  }

  /* Preserve vertical rhythm when multiple lists */

  section ~ section {
    padding-top: rem(24);
  }

`;

export const FaqWrapper = styled.div`
  margin-left: -12%;
  width: 125%;

  @media (${breakpoint.mdMin}) {
    width: 115%;
    margin-left:-6%;
  }

  @media (${breakpoint.xlMin}) {
    margin-left:-11%;
  }
  `;

export const MethodologyWrapper = styled.div`
  margin-left: -12%;
  width: 125%;

  @media (${breakpoint.mdMin}) {
    width: 113%;
    margin-left:-6%;
  }

  @media (${breakpoint.lgMin}) {
   margin-left:-4%;
   width: 75%;
  }
  
  @media (${breakpoint.xlMin}) {
    margin-left:-5%;
   }
  `;

export const RankingListWrapper = styled.div<{ theme: Theme }>`
  background-color: ${getColor('neutralWhite')};
  padding: 4px 16px 16px 16px;
  

  @media (${breakpoint.mdMin}) {
    padding: 4px 24px 24px 24px;
    position: relative;
  }

  @media (${breakpoint.xlMin}) {
    margin-left: calc((100vw - 96px) / 12 * 1 - 20px);
    margin-right: 0;
    max-width: 980px;
    min-width: 874px;
    padding: 16px 24px 24px 24px;
    width: calc(((100vw - 96px) / 12) * 9 - 20px);
  }

  @media (${breakpoint.xxlMin}) {
    margin-left: auto;
  }
  
  section {
    padding-top: 24px;
  }
`;

export const RankingSidebar = styled.div`
  display: flex;
  justify-content: center;

  @media (${breakpoint.lgMin}) {
    display: block;
  }

  @media (${breakpoint.xlMin}) {
    margin-top: 111px;
  }
`;

export const RankingList = styled.ol<{ $num: number }>`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  list-style: none;
  margin-top: 16px;

  @supports (display: grid) {
    display: grid;
    grid-auto-rows: auto;
    grid-gap: 16px;
    grid-template-columns: repeat(2, calc(50% - 8px));

    @media (${breakpoint.mdMin}) {
      grid-gap: 24px;
      grid-template-columns: repeat(3, calc(33% - 8px));
    }

    @media (${breakpoint.xlMin}) {
      grid-gap: 48px;
      ${({ $num }) =>
    ($num === 1 ?
      'grid-template-columns: repeat(1, calc(100% - 48px))' :
      $num === 2 ?
        'grid-template-columns: repeat(2, calc(50% - 48px))' :
        $num === 3 ?
          'grid-template-columns: repeat(3, calc(33% - 48px))' :
          $num === 4 ?
            'grid-template-columns: repeat(4, calc(25% - 48px))' :
            'grid-template-columns: repeat(5, calc(20% - 48px))')}
    }
  }
`;

export const RankingListTitle = styled.div<{ theme: Theme }>`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
  width: 100%;

  h2 {
    font-weight: 600;
    letter-spacing: 0.256px;
    color: ${getColor('neutralDarkGray')};
  }
`;
