import React from 'react';
import { BaseAdConfig } from 'interfaces/ads/Ad';
import { AdsCenteredDiv, InStreamAd } from './Styles';
import AdSlotToggle from './AdSlotToggle';

const SimpleInStream: React.FC<BaseAdConfig> = ({ id, pageType }: BaseAdConfig) => (
  <AdsCenteredDiv>
    <InStreamAd
      className='simple'
      $pageType={pageType}
    >
      <AdSlotToggle
        placementName='SimpleInStream'
        index={id + 1}
        slotId={`SimpleInStream${id}`}
      />
    </InStreamAd>
  </AdsCenteredDiv>
);

export default SimpleInStream;
